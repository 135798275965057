<template>
    <div class="menus-container">
        <h1 class="title">
            {{ $t('menus.weeksMenu') }}
        </h1>
        <p class="subtitle">
            {{ $t('menus.menuWillSwitched') }}
        </p>
        <WeekTypeSection
            class="next-week-section"
            @menuTypeChange="handleMenuTypeChange($event)"
            :title="$t('menus.nextWeeksMenu')"
            :grouped-menu="nextWeekGroupedMenu"
            v-if="nextWeekGroupedMenu"
            :week-type="WeekTypeEnum.NEXT"
        />
        <WeekTypeSection
            class="current-week-section"
            @menuTypeChange="handleMenuTypeChange($event)"
            :title="$t('menus.currentWeeksMenu')"
            :grouped-menu="currentWeekGroupedMenu"
            v-if="currentWeekGroupedMenu"
            :week-type="WeekTypeEnum.CURRENT"
        />
        <Vue3Lottie v-if="showLoader" :animationData="SpinPrimary" class="spinner" />
    </div>
</template>

<script>
    import WeekTypeSection from '@/components/Admin/Menus/WeekTypeSection.vue';
    import { Vue3Lottie } from 'vue3-lottie';
    import { WeekTypeEnum, MenuTypeEnum } from '@/utils/enums';
    import SpinPrimary from '@/assets/animations/spin-primary.json';
    import { mapActions, mapGetters } from 'vuex';
    import { throttle } from 'lodash';

    export default {
        name: 'Menus',
        components: { WeekTypeSection, Vue3Lottie },
        data() {
            return {
                SpinPrimary,
                WeekTypeEnum,
            };
        },
        computed: {
            ...mapGetters('menu', ['nextWeekGroupedMenu', 'currentWeekGroupedMenu']),
            showLoader() {
                return !this.nextWeekGroupedMenu && !this.currentWeekGroupedMenu;
            },
        },
        methods: {
            ...mapActions('menu', ['groupedWeekMenu', 'getAllGroupedMenus']),

            handleMenuTypeChange: throttle(async function (event) {
                try {
                    await this.groupedWeekMenu({
                        type: event.value,
                        weekType: event.weekType,
                    });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            }, 1000),
        },
        async mounted() {
            try {
                await this.getAllGroupedMenus(MenuTypeEnum.REGULAR);
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .spinner {
        margin: 0 auto;
        width: 500px;
    }

    .menus-container {
        .title,
        .subtitle {
            display: none;
        }
        .next-week-section {
            margin-top: 20px;
        }

        .current-week-section {
            margin-top: 60px;
        }
    }

    @include media($lg) {
        .menus-container {
            .title,
            .subtitle {
                display: block;
            }

            .subtitle {
                font-size: $font-14;
                font-style: italic;
            }

            .next-week-section {
                margin-top: 37px;
            }
        }
    }
</style>
