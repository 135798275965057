<template>
    <div class="week-type-section">
        <div class="header">
            <h3>{{ title }}</h3>
            <MenuButtons class="btn__wrapper" @selectMenuType="handleSelectMenuType" :menu-type="menuType" />
            <CRToggle v-model:checked="isExtend" class="extend-toggle">
                {{ $t('common.extendAllSections') }}
            </CRToggle>
        </div>
        <SlideDropDown
            v-for="(menus, index) in dropDownsList"
            :is-extend-all="isExtend"
            :key="index"
            class="slide-down"
        >
            <template #title>
                <h3>{{ $t(`weekDays.${menus[0]}`) }}</h3>
            </template>
            <template #content>
                <CRButton class="create-dish-btn" @click="isCreateModalOpen = true">
                    {{ $t('buttons.createNewDish') }}
                </CRButton>
                <div class="card__wrapper">
                    <DishCard
                        class="card"
                        v-for="menu in menus[1]"
                        :key="menu.id"
                        :dish="menu.dish"
                        :menu="menu"
                        dish-menu-view
                        @select="onDishSelectHandler"
                        @update="openUpdateModal"
                    />
                </div>
            </template>
        </SlideDropDown>
        <DishModals
            :isCreateModalOpen="isCreateModalOpen"
            :isDeleteModalOpen="isDeleteModalOpen"
            :editedDish="editedDish"
            :isEditMode="isEditMode"
            @deleteDish="deleteDish"
            @closeDeleteModal="closeDeleteModal"
            @openDeleteModal="openDeleteModal"
            @closeModal="closeModal"
            @saveDish="updateDish"
        />
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import SlideDropDown from '@/components/Common/DropDown/SlideDropDown.vue';
    import DishCard from '@/components/Admin/Dishes/DishCard.vue';
    import DishModals from '@/components/Admin/Dishes/DishModals.vue';
    import MenuButtons from '@/components/Common/Buttons/MenuButtons.vue';
    import { MenuTypeEnum } from '@/utils/enums';
    import { mapActions, mapMutations } from 'vuex';

    export default {
        name: 'WeekTypeSection',
        components: {
            CRButton,
            CRToggle,
            SlideDropDown,
            DishCard,
            DishModals,
            MenuButtons,
        },
        props: {
            groupedMenu: {
                type: Object,
                default: () => {},
            },
            title: {
                type: String,
                default: '',
            },
            weekType: {
                type: String,
                default: 'Current',
            },
        },
        emits: ['menuTypeChange'],
        data() {
            return {
                menuType: MenuTypeEnum.REGULAR,
                isExtend: false,
                isCreateModalOpen: false,
                isDeleteModalOpen: false,
                isEditMode: false,
                editedDish: null,
                editedMenu: null,
            };
        },
        computed: {
            dropDownsList() {
                return Object.entries(this.groupedMenu[this.menuType] ?? {});
            },
        },
        methods: {
            ...mapActions('menu', ['addDishInMenu', 'removeDishFromMenu']),
            ...mapMutations('menu', ['updateDishesInMenus']),

            handleSelectMenuType(value) {
                this.$emit('menuTypeChange', { value, weekType: this.weekType });
                this.menuType = value;
            },

            onDishSelectHandler(data) {
                this.addDishInMenu(data);
            },

            openUpdateModal(data) {
                this.isEditMode = true;
                this.editedDish = data.dish;
                this.editedMenu = data.menu;
                this.isCreateModalOpen = true;
            },

            async deleteDish() {
                this.removeDishFromMenu({
                    dishId: this.editedDish.id,
                    menuId: this.editedMenu.id,
                });

                this.editedDish = null;
                this.editedMenu = null;
                this.isDeleteModalOpen = false;
            },

            closeDeleteModal() {
                this.isDeleteModalOpen = false;
                this.isCreateModalOpen = true;
            },

            openDeleteModal() {
                this.isDeleteModalOpen = true;
                this.isCreateModalOpen = false;
            },

            closeModal() {
                this.isEditMode = false;
                this.editedDish = null;
                this.editedMenu = null;
                this.isCreateModalOpen = false;
            },

            updateDish(dish) {
                if (this.isEditMode) {
                    this.updateDishesInMenus({ dish, menu: this.editedMenu, dishId: dish.id });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .week-type-section {
        padding-bottom: 30px;
    }

    // header
    .header {
        @include row-align-center;

        flex-wrap: wrap;

        h3 {
            width: 100%;

            margin-bottom: 17px;
        }

        .btn__wrapper {
            width: 100%;
            margin-bottom: 22px;
        }
    }

    @include media($sm) {
        .header {
            @include row-align-center;

            flex-wrap: wrap;

            h3,
            .btn__wrapper {
                width: auto;
                margin-bottom: 0;
            }

            h3 {
                margin-right: 18px;
            }

            .btn__wrapper {
                margin-right: 21px;
            }
        }
    }

    @include media($lg) {
        .header {
            h3 {
                font-size: $font-24;
            }
        }
    }

    // slide-down
    .slide-down {
        margin-top: 18px;
        padding: 10px;

        h3 {
            text-transform: capitalize;
        }

        .create-dish-btn {
            margin-top: 20px;
        }

        .card__wrapper {
            width: 100%;

            .card {
                width: 100%;
                margin-top: 20px;
            }
        }
    }

    @include media($md) {
        .slide-down {
            .card__wrapper {
                @include row-align-start-justify-between;

                flex-wrap: wrap;

                .card {
                    width: 49%;
                    margin-top: 20px;
                }
            }
        }
    }

    @include media($xl) {
        .slide-down {
            .card__wrapper {
                @include row-align-start-justify-start;

                .card {
                    width: 32%;
                    margin-top: 20px;
                    margin-right: 2%;

                    &:nth-child(3),
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
