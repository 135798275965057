<template>
    <div class="dish-card">
        <h3 v-if="!$slots['editable-title']">
            {{ !dishMenuView ? $filters.getTranslationByKey(dish.name) : $t(`common.${menu.mealType}`) }}
        </h3>
        <slot name="editable-title" :isEditMenuNameOpened="isEditMenuNameOpened" :clicked="handelEditTitleClick" />
        <CRSelect
            v-if="dishMenuView"
            class="select"
            :data="dishes"
            :placeholder="$t('placeholder.chooseDish')"
            :selected="0"
            @select="onSelectHandler"
            v-model="searchName"
            :is-initial="isInitial"
            with-search
            max-height="190"
            infinity-select
            @openState="stateOpenedHandler($event)"
            :load="load"
            :label="$filters.getTranslationByKey(dish?.name)"
        />
        <div v-if="dish">
            <h5>{{ $filters.getTranslationByKey(dish.description) }}</h5>
            <div class="tags__wrapper">
                <CRTag class="tag capitalize">
                    {{ $t(`common.${dish.menuType}`) }}
                </CRTag>
                <CRTag class="tag capitalize" :type="!dish.mealType ? 'special' : 'primary'">
                    {{ dish.mealType ?? $t('common.mealUndefined') }}
                </CRTag>
                <CRTag class="tag" v-if="dish.isUnique">
                    {{ $t('dishes.uniqueMenuDish') }}
                </CRTag>
            </div>
            <div class="allergens__wrapper">
                <h3>{{ $t('common.allergens') }}</h3>
                <div v-if="dish.allergens.length" class="tags__wrapper">
                    <CRTag
                        class="tag"
                        type="secondary"
                        icon="vegetarian"
                        v-for="allergen in dish.allergens"
                        :key="allergen.id"
                    >
                        {{ $filters.getTranslationByKey(allergen.name) }}
                    </CRTag>
                </div>
                <p class="no-items" v-else>{{ $t('common.noAllergensDefined') }}</p>
            </div>
            <CRSelect
                class="select mt-10 mb-10"
                :data="filteredMacronutrients"
                max-height="190"
                :selected="$filters.getTranslationByKey(selectedMacronutrient?.name)"
                :placeholder="$filters.getTranslationByKey(selectedMacronutrient?.name)"
                @select="handleMacronutrientSelect"
            />
            <div class="tags__wrapper" v-if="selectedMacronutrient?.configuration">
                <CRTag class="tag" v-if="renderNutrient('calories')">
                    {{ $t('dishes.calories', { amount: renderNutrient('calories') }) }}
                </CRTag>
                <CRTag class="tag" v-if="renderNutrient('carbohydrates')">
                    {{ $t('dishes.carbohydrates', { amount: renderNutrient('carbohydrates') }) }}
                </CRTag>
                <CRTag class="tag" v-if="renderNutrient('fats')">
                    {{ $t('dishes.fats', { amount: renderNutrient('fats') }) }}
                </CRTag>
                <CRTag class="tag" v-if="renderNutrient('proteins')">
                    {{ $t('dishes.proteins', { amount: renderNutrient('proteins') }) }}
                </CRTag>
            </div>
            <div class="rating__wrapper">
                <h3>{{ $t('dishes.evaluationStatus') }}</h3>
                <StarRating read-only v-model="rating">
                    <template #votes>
                        {{ $t('common.votes', { votes: dish.reviewsAmount }) }}
                    </template>
                </StarRating>
            </div>
            <CRButton v-if="!this.menu?.isUnique" @click="update">
                {{ $t('buttons.update') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CRSelect from '@/components/Common/Select/CRSelect.vue';
    import StarRating from '@/components/Common/Rating/StarRating.vue';
    import { DishApi } from '@/api';
    import { debounce, uniqBy } from 'lodash';

    export default {
        name: 'DishCard',
        components: { CRTag, CRButton, CRSelect, StarRating },
        props: {
            dish: {
                type: Object,
                default: () => {},
            },
            dishMenuView: {
                type: Boolean,
                default: false,
            },
            menu: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['select', 'update'],
        data() {
            return {
                page: 1,
                dishes: [],
                searchName: '',
                isInitial: false,
                isEditMenuNameOpened: false,

                macronutrients: [],
                selectedMacronutrient: null,
            };
        },
        computed: {
            rating() {
                return this.dish.rating;
            },

            mealType() {
                if (!this.menu?.isUnique) {
                    return this.menu.mealType.match('snack') ? 'snack' : this.menu.mealType;
                } else {
                    return '';
                }
            },

            filteredMacronutrients() {
                return this.macronutrients?.map((nutrient) => {
                    return {
                        value: nutrient.id,
                        label: this.$filters.getTranslationByKey(nutrient.name),
                        id: nutrient.id,
                    };
                });
            },
        },
        watch: {
            searchName: {
                async handler(val) {
                    await this.searchNameHandler(val);
                },
            },

            'dish.macronutrients': {
                handler(val) {
                    if (val) {
                        this.selectedMacronutrient = val[0];
                    }
                },
            },
        },
        methods: {
            async loadDishes(page, name) {
                try {
                    return await DishApi.getAll({
                        page,
                        name,
                        mealType: this.mealType,
                        menuType: this.menu?.type,
                        isUnique: this.menu?.isUnique,
                    });
                } catch (error) {
                    this.$filters.toast(error.message);
                    return [];
                }
            },

            searchNameHandler: debounce(async function (val) {
                this.isInitial = false;

                const response = await this.loadDishes(1, val);

                const dataForSelect = response.data.map((dish) => {
                    return { label: this.$filters.getTranslationByKey(dish.name), value: dish.id };
                });

                this.page = response.meta.current_page;

                this.dishes = dataForSelect;

                if (!val) {
                    this.resetInfiniteLoader();
                }
            }, 1000),

            async load({ loaded }) {
                const loadedDishes = await this.loadDishes(this.page, this.searchName);

                const dataForSelect = loadedDishes.data.map((dish) => {
                    return { label: this.$filters.getTranslationByKey(dish.name), value: dish.id };
                });

                this.dishes.push(...dataForSelect);

                uniqBy(this.dishes, 'value');

                this.page += 1;
                loaded(dataForSelect.length);
            },

            renderNutrient(field) {
                return this.selectedMacronutrient.configuration?.[field];
            },

            onSelectHandler(item) {
                this.$emit('select', { dishId: item.value, menuId: this.menu.id });
            },

            handelEditTitleClick() {
                this.isEditMenuNameOpened = !this.isEditMenuNameOpened;
            },

            stateOpenedHandler(value) {
                if (value) this.isInitial = false;
                if (!value) {
                    this.page = 1;
                    this.resetInfiniteLoader();
                }
            },

            resetInfiniteLoader() {
                this.isInitial = true;
                this.dishes = [];
            },

            handleMacronutrientSelect(macronutrient) {
                this.selectedMacronutrient = this.macronutrients.find(
                    (searchedMacronutrient) => searchedMacronutrient.id === macronutrient.id
                );
            },

            update() {
                if (this.dishMenuView) {
                    this.$emit('update', { menu: this.menu, dish: this.dish });
                } else {
                    this.$emit('update', this.dish);
                }
            },
        },

        mounted() {
            this.macronutrients = this.dish?.macronutrients ?? [];

            this.selectedMacronutrient = this.macronutrients[0] ?? {};
        },
    };
</script>

<style lang="scss" scoped>
    .dish-card {
        padding: 10px;

        background: $white;

        box-shadow: $box-shadow-dark;
        border-radius: $br-10;

        .select {
            width: 100%;
            margin-bottom: 10px;
        }

        h3,
        h5 {
            margin-bottom: 10px;
        }

        .tags__wrapper {
            @include row-align-center;

            flex-wrap: wrap;

            .tag {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .allergens__wrapper {
            padding: 8px;
            padding-bottom: 0;
            margin-bottom: 10px;

            box-shadow: $box-shadow-dark;
            border-radius: $br-10;

            .tags__wrapper {
                .tag {
                    width: 46%;
                    margin-right: 4%;

                    text-transform: capitalize;
                }
            }

            .no-items {
                font-style: italic;
                padding-bottom: 5px;
            }
        }

        .rating__wrapper {
            padding: 8px;
            margin-bottom: 10px;

            box-shadow: $box-shadow-dark;
            border-radius: $br-10;
        }
    }
</style>
