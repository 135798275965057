<template>
    <div>
        <CreateDishModal
            v-if="isCreateModalOpen"
            :title="isEditMode ? $t('dishes.editDish') : $t('dishes.newDish')"
            :is-edit-mode="isEditMode"
            :edited-dish="editedDish"
            @close="closeModal"
            @openDeleteModal="openDeleteModal"
            @saveDish="saveDish"
        />
        <DeleteConfirmationModal
            v-if="isDeleteModalOpen"
            :title="`${$t('dishes.dish')} - “${$filters.getTranslationByKey(editedDish.name)}”`"
            @close="closeDeleteModal"
            @delete="deleteDish"
        />
    </div>
</template>

<script>
    import CreateDishModal from '@/components/Modals/CreateDishModal.vue';
    import DeleteConfirmationModal from '@/components/Modals/DeleteConfirmationModal.vue';

    export default {
        name: 'DishModals',
        components: { CreateDishModal, DeleteConfirmationModal },
        props: {
            isCreateModalOpen: {
                type: Boolean,
                default: false,
            },
            isDeleteModalOpen: {
                type: Boolean,
                default: false,
            },
            editedDish: {
                type: Object,
                default: null,
            },
            isEditMode: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['deleteDish', 'closeDeleteModal', 'openDeleteModal', 'closeModal', 'saveDish'],
        methods: {
            async deleteDish() {
                this.$emit('deleteDish');
            },

            closeDeleteModal() {
                this.$emit('closeDeleteModal');
            },

            openDeleteModal() {
                this.$emit('openDeleteModal');
            },

            closeModal() {
                this.$emit('closeModal');
            },

            saveDish(data) {
                this.$emit('saveDish', data);
            },
        },
    };
</script>
