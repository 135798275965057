<template>
    <BasicModal @close="$emit('close')">
        <div class="container">
            <h2>{{ title }}</h2>
            <MenuButtons
                class="menu-buttons"
                @selectMenuType="(value) => handelSelect('menuType', value)"
                :menu-type="form.menuType"
            />
            <LangButtons class="lang-buttons" :locale="locale" @selectLocale="selectLocale" />
            <CRInput
                class="input"
                v-model="form.name[locale]"
                :label="$t('common.dishName')"
                :placeholder="$t('placeholder.enterDishName')"
                required
                :errors="errors?.name"
                @onChange="$filters.clearError(errors, 'name')"
            />
            <CRTextarea
                class="input textarea"
                v-model="form.description[locale]"
                :label="$t('common.dishDescription')"
                :placeholder="$t('placeholder.enterDishDescription')"
                required
                :errors="errors?.description"
                @onChange="$filters.clearError(errors, 'description')"
            />
            <div class="section__wrapper">
                <h3>{{ $t('common.regularMenu') }}</h3>
                <div class="btn__wrapper">
                    <CRButton
                        class="btn"
                        v-for="button in dishMealButtonsData"
                        :key="button.value"
                        :active="form.mealType === button.value"
                        @click="handelSelect('mealType', button.value)"
                        pattern="toggle"
                    >
                        {{ button.title }}
                    </CRButton>
                </div>
            </div>
            <div class="section__wrapper">
                <h3>{{ $t('common.uniqueMenu') }}</h3>
                <div class="btn__wrapper">
                    <CRButton
                        class="btn"
                        :active="form.isUnique"
                        @click="form.isUnique = !form.isUnique"
                        pattern="toggle"
                    >
                        {{ $t('dishes.uniqueMenuDish') }}
                    </CRButton>
                </div>
            </div>
            <div class="section__wrapper">
                <h3>{{ $t('common.allergens') }}</h3>
                <div class="btn__wrapper">
                    <CRButton
                        class="btn"
                        v-for="allergen in allergens"
                        :key="allergen.id"
                        :active="form.allergensIds.includes(allergen.id)"
                        @click="handelSelect('allergensIds', allergen.id)"
                        pattern="toggle"
                    >
                        <template #icon>
                            <VegetarianSvg />
                        </template>
                        {{ $filters.getTranslationByKey(allergen.name) }}
                    </CRButton>
                </div>
            </div>
            <div v-if="isEditMode" class="section__wrapper borderless">
                <h3>{{ $t('dishes.caloriesAndNutrients') }}</h3>
                <CRSelect
                    class="select my-15"
                    :data="filteredMacronutrients"
                    max-height="190"
                    :selected="$filters.getTranslationByKey(selectedMacronutrient?.name)"
                    :placeholder="$filters.getTranslationByKey(selectedMacronutrient?.name)"
                    @select="handleMacronutrientSelect"
                />
                <div v-if="form.macronutrients[editedMacronutrientIndex]?.configuration">
                    <CRInput
                        class="input my-25"
                        v-model.number="form.macronutrients[editedMacronutrientIndex].configuration.calories"
                        :label="$t('common.calorieNorm')"
                        :placeholder="$t('placeholder.enterCalorie')"
                        type="number"
                        required
                        :errors="errors?.calories"
                        @onChange="$filters.clearError(errors, 'calories')"
                    />
                    <CRInput
                        class="input my-25"
                        v-model.number="form.macronutrients[editedMacronutrientIndex].configuration.carbohydrates"
                        :label="$t('common.carbohydrates')"
                        :placeholder="$t('placeholder.enterCarbohydrates')"
                        type="number"
                        required
                        :errors="errors?.carbohydrates"
                        @onChange="$filters.clearError(errors, 'carbohydrates')"
                    />
                    <CRInput
                        class="input my-25"
                        v-model.number="form.macronutrients[editedMacronutrientIndex].configuration.fats"
                        :label="$t('common.fats')"
                        :placeholder="$t('placeholder.enterFats')"
                        type="number"
                        required
                        :errors="errors?.fats"
                        @onChange="$filters.clearError(errors, 'fats')"
                    />
                    <CRInput
                        class="input"
                        v-model.number="form.macronutrients[editedMacronutrientIndex].configuration.proteins"
                        :label="$t('common.proteins')"
                        :placeholder="$t('placeholder.enterProteins')"
                        type="number"
                        required
                        :errors="errors?.proteins"
                        @onChange="$filters.clearError(errors, 'proteins')"
                    />
                </div>
            </div>

            <RequiredText class="w-full" />

            <div class="menu-buttons">
                <CRButton class="btn" @click="saveDish" :loading="isLoading">
                    {{ $t('buttons.save') }}
                </CRButton>
                <CRButton
                    class="btn"
                    @click="deleteDish"
                    :pattern="isEditMode ? 'fill' : 'outline'"
                    :color="isEditMode ? 'danger' : 'primary'"
                >
                    {{ isEditMode ? $t('buttons.delete') : $t('buttons.cancel') }}
                </CRButton>
            </div>
        </div>
    </BasicModal>
</template>

<script>
    import RequiredText from '@/components/Common/Tags/RequiredText.vue';
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRInput from '@/components/Common/Inputs/CRInput.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import VegetarianSvg from '@/assets/icons/tags/vegetarian.svg?inline';
    import CRTextarea from '@/components/Common/Textarea/CRTextarea.vue';
    import CRSelect from '@/components/Common/Select/CRSelect.vue';
    import MenuButtons from '@/components/Common/Buttons/MenuButtons.vue';
    import { dishMealButtonsData } from '@/config/dishes/dishes';
    import { MenuTypeEnum, AllergenTypeEnum } from '@/utils/enums';
    import { langMixin } from '@/utils/mixins';
    import { AllergenApi, DishApi } from '@/api';
    import { cloneDeep } from 'lodash';

    const CONFIGURATION_DEFAULT_FORM = {
        calories: null,
        carbohydrates: null,
        fats: null,
        proteins: null,
    };

    export default {
        name: 'CreateDishModal',
        components: {
            BasicModal,
            CRButton,
            CRInput,
            CRTextarea,
            VegetarianSvg,
            MenuButtons,
            CRSelect,
            RequiredText,
        },
        props: {
            title: {
                type: String,
                default: '',
            },
            isEditMode: {
                type: Boolean,
                default: false,
            },
            editedDish: {
                type: Object,
                default: null,
            },
        },
        mixins: [langMixin],
        emits: ['close', 'saveDish', 'openDeleteModal'],
        data() {
            return {
                dishMealButtonsData,
                allergens: [],

                isLoading: false,

                form: {
                    menuType: MenuTypeEnum.VEGETARIAN,
                    name: {
                        en: '',
                        lt: '',
                    },
                    description: {
                        en: '',
                        lt: '',
                    },
                    mealType: '',
                    isUnique: false,
                    allergensIds: [],
                    calories: '',
                    carbohydrates: '',
                    fats: '',
                    proteins: '',
                    macronutrients: [],
                },

                configurationForm: { ...CONFIGURATION_DEFAULT_FORM },
                selectedMacronutrient: {},

                errors: {},
            };
        },

        computed: {
            filteredMacronutrients() {
                return this.form.macronutrients?.map((nutrient) => {
                    return {
                        value: nutrient.id,
                        label: this.$filters.getTranslationByKey(nutrient.name),
                        id: nutrient.id,
                    };
                });
            },

            editedMacronutrientIndex() {
                return this.form.macronutrients.findIndex(
                    (macronutrient) => macronutrient.id === this.selectedMacronutrient.id
                );
            },
        },

        methods: {
            handelSelect(field, value) {
                if (field === 'allergensIds') {
                    if (this.form.allergensIds.includes(value)) {
                        this.form.allergensIds = this.form.allergensIds.filter((itemId) => itemId !== value);
                    } else {
                        this.form.allergensIds.push(value);
                    }
                } else {
                    if (this.form[field] === value) {
                        this.form[field] = '';
                    } else {
                        this.form[field] = value;
                    }
                }
            },

            handleMacronutrientSelect(macronutrient) {
                this.selectedMacronutrient = this.form.macronutrients.find(
                    (searchedMacronutrient) => searchedMacronutrient.id === macronutrient.id
                );

                this.setSelectedMacronutrientsConfigurationToDefault();
            },

            async getAllAllergens() {
                try {
                    const response = await AllergenApi.getAll({ type: AllergenTypeEnum.DISH });
                    this.allergens = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async saveDish() {
                this.isLoading = true;

                try {
                    let response;

                    if (this.isEditMode) {
                        response = await DishApi.update(this.editedDish.id, this.form);
                    } else {
                        response = await DishApi.store(this.form);
                    }

                    this.$emit('saveDish', response.data);
                    this.$emit('close');
                } catch (error) {
                    this.errors = error.errors;
                } finally {
                    this.isLoading = false;
                }
            },

            async deleteDish() {
                if (this.isEditMode) {
                    this.$emit('openDeleteModal');
                } else {
                    this.$emit('close');
                }
            },

            setSelectedMacronutrientsConfigurationToDefault() {
                !this.selectedMacronutrient.configuration &&
                    (this.selectedMacronutrient.configuration = { ...CONFIGURATION_DEFAULT_FORM });
            },

            prepareEditData() {
                const allergensIds = this.editedDish.allergens.map((allergen) => allergen.id);

                this.form = {
                    menuType: this.editedDish.menuType,
                    name: this.editedDish.name,
                    description: this.editedDish.description,
                    mealType: this.editedDish.mealType,
                    isUnique: this.editedDish.isUnique,
                    allergensIds: allergensIds,
                    calories: this.editedDish.calories,
                    carbohydrates: this.editedDish.carbohydrates,
                    fats: this.editedDish.fats,
                    proteins: this.editedDish.proteins,
                    macronutrients: cloneDeep(this.editedDish.macronutrients),
                };

                this.selectedMacronutrient = this.form.macronutrients[0] ?? {};

                this.setSelectedMacronutrientsConfigurationToDefault();
            },
        },

        watch: {
            selectedMacronutrient(val, oldVal) {
                oldVal && (oldVal.configuration = this.configurationForm);
                if (val.configuration) this.configurationForm = val.configuration;
                else this.configurationForm = { ...CONFIGURATION_DEFAULT_FORM };
            },
        },

        async created() {
            await this.getAllAllergens();

            if (this.isEditMode) {
                this.prepareEditData();
            }
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-start-justify-start;

        width: 100%;

        padding: 20px;

        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        h2 {
            margin-bottom: 10px;
        }

        .lang-buttons {
            margin-top: 20px;
        }

        .menu-buttons {
            @include row-align-center;
            flex-wrap: wrap;

            .btn {
                margin-right: 8px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .select {
            width: auto;
        }

        .input {
            margin-top: 24px;
            margin-bottom: 22px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .textarea {
            margin-top: 0;
            margin-bottom: 10px;

            :deep(.cr-textarea) {
                min-height: 115px;
            }
        }

        .borderless {
            box-shadow: none !important;
        }

        .section__wrapper {
            width: 100%;

            padding: 8px;
            padding-bottom: 0;
            margin-bottom: 10px;

            box-shadow: $box-shadow-dark;
            border-radius: $br-10;

            h3 {
                margin-bottom: 10px;
            }

            .btn__wrapper {
                @include row-align-center;
                flex-wrap: wrap;

                .btn {
                    margin-right: 8px;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
